<template>
  <ion-list>
    <ion-list-header v-text="title"></ion-list-header>
    <div v-for="(item, key) in items" :key="key">
      <ion-item>
        <avatar slot="start" :initials="getInitials(item)" :value="item.profile_photo_tn"/>
        <ion-label @click="actionJoin(item)">
          <h3 v-text="item.time"></h3>
          <p v-text="item.name"></p>
          <p v-text="item.owner_name"></p>
        </ion-label>
        <ion-button slot="end" :color="getStatusColor(item)" fill="clear" @click="actionGoing(item)">
          <ion-label>{{ getStatusText(item) }}</ion-label>
          <ion-icon :icon="chevronDownOutline" slot="end"></ion-icon>
        </ion-button>
      </ion-item>
      <img
        v-if="['ptic'].includes(item.stripe_products_slug)"
        class="club-badge"
        src="@/assets/images/ptic.png"
        alt="ptic"
      >
    </div>
  </ion-list>
</template>

<script>
import {
  loadingController,
  IonListHeader,
  modalController, IonIcon, actionSheetController
} from '@ionic/vue'
import {API} from "@/utils/apiWrapper"
import AuthView from '@/views/AuthService'
import {layout} from '@/utils/layout'
import DeclineModal from '@/views/Sessions/DeclineModal'
import dayjs from "dayjs";
import Avatar from "@/components/Avatar";
import {chevronDownOutline} from "ionicons/icons";

const SESSION_STATUS = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
}

export default {
  name: 'SessionsIndex',
  mixins: [AuthView, layout],
  components: {
    IonListHeader,
    Avatar,
    IonIcon
  },
  props: {
    items: Array,
    title: String
  },
  methods: {
    async actionGoing(item) {
      const actionSheet = await actionSheetController.create({
        header: this.__('interface.going'),
        buttons: [
          {
            text: this.__('interface.yes'),
            handler: async () => {
              await this.accept(item)
            }
          },
          {
            text: this.__('interface.no'),
            role: 'destructive',
            handler: () => {
              this.decline(item)
            }
          },
          {
            text: this.__('interface.cancel'),
            role: 'cancel'
          }
        ]
      });
      return actionSheet.present();
    },
    getStatus(item) {
      const sessionId = item.id
      const contextDate = dayjs(item.start_date).format('YYYY-MM-DD')
      const statuses = item.statuses ?? []
      const itemStatus = statuses.find(status => {
        return status.context_date === contextDate && status.session_id === sessionId
      })

      return itemStatus !== undefined ? itemStatus.status : null
    },
    getStatusText(item) {
      if (this.getStatus(item) === SESSION_STATUS.ACCEPTED) {
        return this.__('interface.accepted')
      }

      if (this.getStatus(item) === SESSION_STATUS.DECLINED) {
        return this.__('interface.declined')
      }

      return this.__('interface.going')
    },
    getStatusColor(item) {
      if (this.getStatus(item) === SESSION_STATUS.ACCEPTED) {
        return 'success'
      }

      if (this.getStatus(item) === SESSION_STATUS.DECLINED) {
        return 'danger'
      }

      return 'secondary'
    },
    getInitials(session) {
      let initials = ''
      const name = session.name ?? ''

      name.split(' ').forEach(word => {
        initials += word.substring(0, 1)
      })

      return initials
    },
    async actionJoin(data) {
      await this.$router.push({
        name: 'SessionWaitingRoom',
        params: {
          sessionId: data.id,
          ownerId: data.owner_id,
          contextDate: dayjs(data.start_date).toISOString()
        }
      })
    },
    async decline(session) {
      const modal = await modalController
        .create({
          component: DeclineModal,
          componentProps: {
            close: this.modalClose,
            decline: this.doDecline,
            session: session
          },
        })
      await modal.present()
    },
    doDecline: async function (item, message) {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      })
      await loading.present()

      API.post('TheSpottApi', '/session-decline', {
        body: {
          id: item.id,
          contextDate: dayjs(item.start_date).format('YYYY-MM-DD'),
          message: message
        }
      }).then(() => {
        this.setStatus({item, status: SESSION_STATUS.DECLINED})
        this.$tracking.event({
            eventName: 'session-decline',
            category: 'sessions',
            event: {
              id: item.id,
              contextDate: dayjs(item.start_date).format('YYYY-MM-DD'),
              message: message
            }
        });
      }).finally(() => {
        loading.dismiss()
        this.modalClose()
      })
    },
    modalClose: function () {
      modalController.dismiss()
    },
    setStatus({item, status}) {
      if (item.statuses === null) {
        item.statuses = []
      }

      const sessionId = item.id
      const contextDate = dayjs(item.start_date).format('YYYY-MM-DD')
      const exist = item.statuses.find(statusItem => {
        return statusItem.session_id === sessionId && statusItem.context_date === contextDate
      })

      if (exist !== undefined) {
        exist.status = status
      } else {
        item.statuses.push({
          session_id: sessionId,
          context_date: contextDate,
          status: status
        })
      }
    },
    async accept(item) {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      })
      await loading.present()

      API.post('TheSpottApi', '/session-accept', {
        body: {
          id: item.id,
          contextDate: dayjs(item.start_date).format('YYYY-MM-DD')
        }
      }).then(() => {
        this.setStatus({item, status: SESSION_STATUS.ACCEPTED})
        this.$tracking.event({
            eventName: 'session-accept',
            category: 'sessions',
            event: {
              id: item.id,
              contextDate: dayjs(item.start_date).format('YYYY-MM-DD')
            }
        });
      }).finally(() => {
        loading.dismiss()
      })
    },
  },
  setup() {
    return {
      chevronDownOutline
    }
  }
}
</script>

<style lang="scss" scoped>
ion-icon {
  font-size: 12px;
}

.club-badge {
  position: absolute;
  bottom: 16px;
  left: 40px;
  width: 18px;
  height: 18px;
  z-index: 1;
}
</style>
