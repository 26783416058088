export const layout = {
  computed: {
    isDarkMode() {
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    },
    logoSrc () {
      return require(this.isDarkMode ? "@/assets/images/logo-white.svg" : "@/assets/images/logo-color.svg")
    },
  }
}

