import {RRule} from "rrule";
import dayjs from "dayjs";

export default {
  methods: {
    formatDate (dateString) {
      const sessionDate = new Date(dateString)
      const today = new Date()
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)

      const sessionDateFormatted = sessionDate.toLocaleDateString()
      if (sessionDateFormatted === today.toLocaleDateString()) {
        return this.__('interface.sessions.today')
      }

      if (sessionDateFormatted === tomorrow.toLocaleDateString()) {
        return this.__('interface.sessions.tomorrow')
      }

      return sessionDateFormatted
    },
    parseResponse (response) {
      const events = []

      response.data.forEach(event => {
        const rrule = RRule.fromString(event.rrule);
        const today = dayjs().startOf('day');
        const rruleStartOffset = rrule.options.dtstart.getTimezoneOffset()
        rrule.between(today.toDate(), today.add(7, 'd').toDate()).forEach(date => {
          const eventStartOffset = date.getTimezoneOffset()
          const newEvent = {...event}
          const dateDayjs = dayjs(date).add(eventStartOffset - rruleStartOffset, 'minute')
          newEvent.start_date = dateDayjs.toISOString()
          events.push(newEvent)
        })
      })

      return (events).sort(function (a, b) {
        return a.start_date.localeCompare(b.start_date)
      }).reduce((groups, session) => {

        const date = this.formatDate(session.start_date)
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push({
          ...session,
          time: new Date(session.start_date).toLocaleTimeString([], { timeStyle: 'short' })
        })
        return groups
      }, {})
    },
  }
}