<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/" @click="this.close()"></ion-back-button>
      </ion-buttons>
      <ion-title>{{ __('interface.session-decline-title') }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content :fullscreen="true">
    <p class="ion-margin-horizontal">{{
        __('interface.session-decline-question', session.name, new Date(session.start_date).toLocaleDateString(), session.time)
      }}</p>
    <p class="ion-margin-horizontal">{{ __('interface.session-decline-provide-message', session.owner_name) }}</p>
    <ion-item>
      <ion-label position="floating">{{ __('interface.message') }}</ion-label>
      <ion-textarea
        required
        type="text"
        name="title"
        spellcheck="true"
        v-model="message"
        auto-grow
      ></ion-textarea>
    </ion-item>
    <ion-button class="ion-margin-vertical" expand="full" @click="this.decline(this.session, this.message)"
                color="danger">
      {{ __('interface.decline') }}
    </ion-button>
  </ion-content>

</template>

<script>
import {IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonTextarea, IonBackButton} from '@ionic/vue'
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'DeclineModal',
  data() {
    return {
      message: '',
    }
  },
  props: {
    close: {type: Function},
    decline: {type: Function},
    session: {type: Object}
  },
  components: {
    IonItem,
    IonLabel,
    IonTextarea,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton
  }
})
</script>
